import { getBrandParent } from 'country-configuration/utils/brands/getBrandParent'
import type { Brand, BrandId } from 'types/brands'
import type { UserDataProviderProps } from 'user-data/context/UserDataProvider'
import { isBot } from 'utils/isBot/IsBot'

import { getUserData } from '../getUserData'
import { setUserData } from '../setUserData'
import { getDeviceData } from './device/getDeviceData'
import { setUserDataLocalStorage } from './localStorage/setUserDataLocalStorage'

export interface HandleUserData {
	dispatch: (newState: Partial<UserDataProviderProps>) => void
	brand: BrandId | null
	subBrand: BrandId | null
	countryBrands: Brand[]
	countryISO: string
	languageISO: string
}

const getSubBrandValue = (
	brandId: BrandId | null,
	subBrandId: BrandId | null,
	userSubBrandID: BrandId | null | undefined,
	countryBrands: Brand[]
): BrandId | null | undefined => {
	if (!brandId) {
		return userSubBrandID
	}

	if (!subBrandId) {
		const parentBrandId = userSubBrandID
			? getBrandParent(userSubBrandID, countryBrands).id
			: null

		return parentBrandId === brandId ? userSubBrandID : undefined
	}

	return subBrandId
}

export const handleUserData = async ({
	brand,
	subBrand,
	countryBrands,
	dispatch,
	countryISO,
	languageISO,
}: HandleUserData) => {
	if (!isBot()) {
		const userData = await getUserData()
		if (userData?.userCountryISO) {
			const { userCountryISO, userLanguageISO, userBrandID, userSubBrandID } =
				userData

			const newUserData = {
				userCountryISO,
				userLanguageISO,
				userBrandID: brand ?? userBrandID,
				userSubBrandID: getSubBrandValue(
					brand,
					subBrand,
					userSubBrandID,
					countryBrands
				),
			}

			dispatch(newUserData)

			if (
				userBrandID !== newUserData.userBrandID ||
				userSubBrandID !== newUserData.userSubBrandID
			) {
				setUserData(newUserData)
			} else {
				setUserDataLocalStorage(userData)
			}
		} else {
			const { country: deviceCountry } = await getDeviceData()
			if (deviceCountry === countryISO) {
				const payload = {
					userCountryISO: countryISO.toLowerCase(),
					userLanguageISO: languageISO,
					userBrandID: brand,
					userSubBrandID: subBrand,
				}
				setUserData(payload)
				dispatch(payload)
			}
		}
	}
	dispatch({ loading: false })
}
