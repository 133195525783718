import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useEffect } from 'react'
import { handleUserData } from 'user-data/utils/handleUserData'

import type { UserDataProviderProps } from '../context/UserDataProvider'

export const useHandleUserData = (
	dispatch: (newState: Partial<UserDataProviderProps>) => void
) => {
	const {
		brand,
		subBrand,
		country: { countryISO, languageISO, brands },
	} = useMasterData()

	useEffect(() => {
		handleUserData({
			countryBrands: brands,
			brand,
			subBrand,
			dispatch,
			countryISO,
			languageISO,
		})
	}, [brand, subBrand, countryISO, languageISO])

	return null
}
