import type { UserData, UserDataStorage } from 'types/userData'
import { USER_DATA_LOCAL_STORAGE_KEY } from 'user-data/constants'
import { getFromLocalStorage } from 'utils/webStorage/localStorage'

export const getUserDataLocalStorage = (): UserData | null => {
	const UserDataLocalStorage = getFromLocalStorage<UserDataStorage | null>(
		USER_DATA_LOCAL_STORAGE_KEY
	)
	if (UserDataLocalStorage) {
		const { countryISO, languageISO, brandID, subBrandID } =
			UserDataLocalStorage
		return {
			userCountryISO: countryISO,
			userLanguageISO: languageISO,
			userBrandID: brandID,
			userSubBrandID: subBrandID,
		}
	}
	return null
}
