import type { Brand, BrandId } from 'types/brands'

import { getSubbrands } from './getSubBrands'

// Recursive function to get the parent of a brand
export const getBrandParent = (brandId: BrandId, brands: Brand[]): Brand => {
	const matchedBrand = brands.find((brand) => brand.id === brandId)

	if (matchedBrand) {
		return matchedBrand
	}

	const subBrands = getSubbrands(brands)

	if (subBrands.length > 0) {
		const subBrand = getBrandParent(brandId, subBrands)
		if (subBrand) {
			const parentBrand = brands.find((brand) =>
				brand.subBrands?.includes(subBrand)
			)
			if (parentBrand) {
				return parentBrand
			}
		}
	}

	throw new Error(`Brand with id ${brandId} doesn't exist`)
}
