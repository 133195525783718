'use client'

import { useCookiesContext } from 'cookies/hooks/useCookiesContext'
import { MODAL_TYPE, Modal, type ModalAPI } from 'fukku/Modal'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import dynamic from 'next/dynamic'
import { useEffect, useRef } from 'react'
import { useUserData } from 'user-data/hooks/useUserData'
import { setUserData } from 'user-data/setUserData'
import { isBot } from 'utils/isBot/IsBot'

const DynamicCountryLanguage = dynamic(() =>
	import('country-language/components/CountryLanguage/CountryLanguage').then(
		(mod) => mod.CountryLanguage
	)
)

export function ModalGeolocation(): React.ReactNode {
	const modalRef = useRef<ModalAPI>(null)
	const {
		userCountryISO,
		userLanguageISO,
		userBrandID,
		userSubBrandID,
		loading,
	} = useUserData()
	const { cookiesConsent } = useCookiesContext()
	const isFromSubmit = useRef<boolean>(false)

	const {
		brand,
		subBrand,
		country: { countryISO, languageISO },
	} = useMasterData()
	const { t } = useLabels()

	useEffect(() => {
		if (
			!loading &&
			(userCountryISO !== countryISO.toLowerCase() ||
				userLanguageISO !== languageISO.toLowerCase()) &&
			cookiesConsent
		) {
			modalRef.current?.show()
		}
	}, [loading, userCountryISO, userLanguageISO, cookiesConsent])

	const closeModal = (submit: boolean): void => {
		isFromSubmit.current = submit
		modalRef.current?.close()
	}

	const onClose = async (): Promise<void> => {
		if (!isFromSubmit.current) {
			await setUserData({
				userCountryISO: countryISO,
				userLanguageISO: languageISO,
				userBrandID: brand ?? userBrandID ?? null,
				userSubBrandID: brand ? subBrand : userSubBrandID,
			})
		}
	}

	return cookiesConsent && !isBot() ? (
		<Modal
			name='geolocation-modal'
			ref={modalRef}
			type={MODAL_TYPE.M}
			data-testid='menu.changeCountryLanguage.geolocation.modal'
			title={t('countrySelector.footer.changeCountry.title')}
			onClose={onClose}
		>
			<DynamicCountryLanguage isFromGeolocation closeModal={closeModal} />
		</Modal>
	) : null
}
